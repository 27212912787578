<template>
  <div class="min-h-full">
    <div
      v-if="environment !== 'production'"
      class="absolute right-0 top-0 m-4 rounded bg-maple px-1 py-0.5 font-mono text-xs text-white"
    >
      {{
        environment === 'development'
          ? 'DEVELOPMENT'
          : environment === 'preview'
            ? 'PREVIEW'
            : ''
      }}
    </div>
    <Popover
      v-slot="{ open, close }"
      as="header"
      class="pb-24"
      :class="
        environment === 'development'
          ? 'bg-black'
          : environment == 'preview'
            ? 'bg-lagoon-700'
            : 'bg-pine'
      "
    >
      <div class="mx-auto max-w-3xl px-4 lg:max-w-7xl lg:px-8">
        <!-- Mobile -->
        <div
          class="relative flex h-16 items-center justify-center py-5 lg:justify-between"
        >
          <!-- Logo -->
          <div class="absolute left-0 shrink-0 lg:static">
            <button @click="router.back()">
              <Icon
                icon="tabler:arrow-left"
                class="mr-3 inline size-8 text-white md:hidden"
                aria-hidden="true"
              />
            </button>
            <a href="/">
              <span class="sr-only">HumanForest</span>
              <img
                class="hidden md:block md:h-5 md:w-auto"
                src="/logo.svg"
                alt="HumanForest"
              />
              <img
                class="inline h-auto w-10 md:hidden"
                src="/symbol.svg"
                alt="HumanForest"
              />
            </a>
          </div>
          <div class="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
            <Menu as="div" class="relative ml-4 shrink-0">
              <div class="inline-flex items-center">
                <div class="mr-4 flex flex-col">
                  <p class="text-white">
                    {{ error ? 'NO NAME' : profile.name }}
                  </p>
                  <p class="text-sm text-forest-400">{{ user?.email }}</p>
                </div>
                <div>
                  <MenuButton
                    class="flex rounded-full bg-white text-sm ring-2 ring-white/20 focus:outline-none focus:ring-white/100"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img
                      class="h-8 w-8 rounded-full"
                      src="/avatar.png"
                      alt=""
                    />
                  </MenuButton>
                </div>
              </div>
              <transition
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute -right-2 z-40 mt-2 w-48 origin-top-right rounded-lg border-2 border-forest bg-white py-1 shadow-offset shadow-forest ring-1 ring-black/5 focus:outline-none"
                >
                  <MenuItem v-slot="{ active }">
                    <NuxtLink
                      to="/profile"
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block cursor-pointer px-4 py-2 text-sm text-gray-700',
                      ]"
                    >
                      Profile
                    </NuxtLink>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <NuxtLink
                      :class="[
                        active ? 'bg-gray-100' : '',
                        'block cursor-pointer px-4 py-2 text-sm text-gray-700',
                      ]"
                      @click="logout"
                    >
                      Sign out
                    </NuxtLink>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>

          <!-- Search -->
          <div
            v-if="userHasPermission(user, 'canSearch')"
            class="ml-6 min-w-0 flex-1 pl-16 pr-8 lg:hidden"
          >
            <div class="mx-auto w-full max-w-xs">
              <label for="mobile-search" class="sr-only">Search</label>
              <div class="relative mx-4 text-white focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                >
                  <Icon
                    icon="tabler:search"
                    class="h-5 w-5"
                    aria-hidden="true"
                  />
                </div>
                <div
                  class="flex overflow-hidden rounded-full border-2 border-white/40 focus-within:border-forest focus-within:bg-white focus-within:text-forest focus-within:ring-2 focus-within:ring-leaf focus-within:ring-offset-2 focus-within:ring-offset-pine focus-within:placeholder:text-forest-400"
                >
                  <input
                    id="mobile-search"
                    v-model="mobileSearch"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    type="number"
                    class="block w-full appearance-none border-none bg-white/20 py-2 pl-10 leading-5 text-white placeholder:text-forest-300 focus:appearance-none focus:text-forest lg:text-sm"
                    placeholder="Search Vehicle"
                    name="search"
                    @keyup.enter="handleMobileSearch"
                  />
                  <button
                    :disabled="!mobileSearch"
                    class="relative -ml-px inline-flex items-center space-x-2 bg-leaf px-3 py-2 text-sm font-medium text-white focus:border-white focus:outline-none focus:ring focus:ring-white disabled:bg-pine"
                    @click="handleMobileSearch"
                  >
                    <Icon
                      icon="tabler:arrow-right"
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Menu button -->
          <div class="absolute right-0 shrink-0 lg:hidden">
            <PopoverButton
              class="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-white hover:bg-white/10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
            >
              <span class="sr-only">Open main menu</span>
              <Icon
                v-if="!open"
                icon="tabler:menu-2"
                class="block h-6 w-6"
                aria-hidden="true"
              />
              <Icon
                v-else
                icon="tabler:x"
                class="block h-6 w-6"
                aria-hidden="true"
              />
            </PopoverButton>
          </div>
        </div>

        <!-- Desktop -->
        <div class="hidden border-t-2 border-white/20 py-5 lg:block">
          <div class="grid grid-cols-3 items-center gap-4">
            <div class="col-span-2">
              <nav class="flex space-x-4">
                <NuxtLink
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[
                    item.href === '/' + route.path.split('/')[1]
                      ? 'border-2 border-forest bg-leaf text-white shadow-offset shadow-forest'
                      : 'border-2 border-transparent text-white',
                    'rounded-md px-3 py-2 text-base font-medium hover:border-2 hover:border-forest hover:bg-leaf',
                  ]"
                  :aria-current="
                    item.href === '/' + route.path.split('/')[1]
                      ? 'page'
                      : undefined
                  "
                >
                  {{ item.name }}
                </NuxtLink>
              </nav>
            </div>
            <div v-if="userHasPermission(user, 'canSearch')">
              <div class="mx-auto w-full max-w-md">
                <label for="desktop-search" class="sr-only">Search</label>
                <div class="relative text-white focus-within:text-gray-600">
                  <div
                    class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                  >
                    <Icon
                      icon="tabler:search"
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    id="desktop-search"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="block w-full appearance-none rounded-full border-2 border-white/20 bg-white/20 py-2 pl-10 leading-5 text-white placeholder:text-forest-300 focus:appearance-none focus:border-transparent focus:bg-white focus:text-forest focus:ring focus:ring-leaf focus:placeholder:text-forest-400 sm:text-sm"
                    placeholder="Search by Vehicle Id"
                    name="search"
                    @keyup.enter="handleSearch"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TransitionRoot as="template" :show="open">
        <div class="lg:hidden">
          <TransitionChild
            as="template"
            enter="duration-150 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-150 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <PopoverOverlay class="fixed inset-0 z-[90] bg-forest/75" />
          </TransitionChild>

          <TransitionChild
            as="template"
            enter="duration-150 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-150 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <PopoverPanel
              focus
              class="absolute inset-x-0 top-0 z-[100] mx-auto w-full max-w-3xl origin-top p-2 transition"
            >
              <div
                class="divide-y divide-forest rounded-xl border-2 border-forest bg-white"
              >
                <div class="pb-2 pt-3">
                  <div class="flex items-center justify-between px-4">
                    <div>
                      <img
                        class="h-8 w-auto"
                        src="/symbol-pine.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div class="-mr-2">
                      <PopoverButton
                        class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-leaf"
                      >
                        <span class="sr-only">Close menu</span>
                        <Icon
                          icon="tabler:x"
                          class="h-6 w-6"
                          aria-hidden="true"
                        />
                      </PopoverButton>
                    </div>
                  </div>
                  <div class="mt-3 space-y-1 px-2">
                    <NuxtLink
                      v-for="item in navigation"
                      :key="item.name"
                      :href="item.href"
                      class="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                      @click="close"
                    >
                      {{ item.name }}
                    </NuxtLink>
                  </div>
                </div>
                <div class="pb-2 pt-4">
                  <div class="flex items-center px-5">
                    <div class="shrink-0">
                      <img
                        class="h-10 w-10 rounded-full"
                        src="/avatar.png"
                        alt=""
                      />
                    </div>
                    <div class="ml-3 min-w-0 flex-1">
                      <div
                        class="inline-flex items-center truncate text-base font-medium text-gray-800"
                      >
                        {{ error ? 'NO NAME' : profile.name }}
                        <a href="/profile">
                          <Icon
                            icon="tabler:edit"
                            class="ml-2 text-forest-300"
                          />
                        </a>
                      </div>
                      <div class="truncate text-sm font-medium text-gray-500">
                        {{ user?.email }}
                      </div>
                    </div>
                  </div>
                  <div class="mt-3 space-y-1 px-2">
                    <NuxtLink
                      class="block cursor-pointer rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                      @click="logout"
                    >
                      Sign out
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </TransitionChild>
        </div>
      </TransitionRoot>
    </Popover>
    <main class="-mt-24 pb-8">
      <div class="mx-auto max-w-3xl px-4 lg:max-w-7xl lg:px-8">
        <slot />
      </div>
    </main>

    <!-- QR Code Scanner -->
    <div>
      <Dialog
        v-if="isOpen"
        :open="isOpen"
        class="relative z-50"
        :unmount="false"
        @close="setIsOpen"
      >
        <div class="fixed inset-0 bg-black/30" aria-hidden="true"></div>
        <div class="fixed inset-0 overflow-y-auto p-4">
          <div class="flex min-h-full items-center justify-center">
            <DialogPanel
              class="w-full max-w-md rounded-2xl border-2 border-forest bg-white p-6 text-left align-middle shadow-offset shadow-forest transition-all"
            >
              <div
                class="absolute -right-4 -top-4 inline-flex size-8 items-center justify-center rounded-full border-2 border-forest bg-maple p-1 text-white shadow-offset shadow-forest"
              >
                <button type="button" @click="setIsOpen(false)">
                  <Icon icon="tabler:x" class="size-4" />
                </button>
              </div>
              <ClientOnly>
                <QrReader />
              </ClientOnly>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <div class="fixed bottom-0 right-0 z-50 m-5">
        <HButton
          icon="tabler:qrcode"
          size="xl"
          rounded
          rounded-full
          offset
          @click="setIsOpen(!isOpen)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverOverlay,
  PopoverPanel,
  TransitionChild,
  TransitionRoot,
  Dialog,
  DialogPanel,
} from '@headlessui/vue'
import { Icon } from '@iconify/vue'
import { HButton, QrReader } from '#components'
import { userHasPermission } from '~/utils/roles'

const { environment } = useRuntimeConfig().public
const route = useRoute()
const user = useSupabaseUser()
const client = useSupabaseClient()
const router = useRouter()

const { data: profile, error } = await client
  .from('profiles')
  .select('*')
  .eq('id', user.value?.id)
  .limit(1)
  .single()

const isOpen = ref(false)
const setIsOpen = (value: boolean) => {
  isOpen.value = value
}

const navigationItems = [
  { name: 'Map', href: '/map', permission: 'canAccessMap' },
  { name: 'Vehicles', href: '/vehicles', permission: 'canAccessVehicles' },
  { name: 'Reports', href: '/reports', permission: 'canAccessReports' },
  { name: 'Users', href: '/users', permission: 'canManageUsers' },
]

const navigation: { name: string; href: string }[] = navigationItems.filter(
  (item) => userHasPermission(user.value, item.permission),
)

const handleSearch = (event: KeyboardEvent) => {
  event.preventDefault()
  const inputElement = event.target as HTMLInputElement
  navigateTo(`/vehicles/${inputElement.value}`)
  inputElement.value = ''
}

const mobileSearch = ref(null)
const handleMobileSearch = () => {
  navigateTo(`/vehicles/${mobileSearch.value}`)
  mobileSearch.value = null
}

const logout = async () => {
  localStorage.removeItem('vehicleFilters')
  localStorage.removeItem('mapSettings')
  await client.auth.signOut()
  navigateTo('/')
}
</script>

<style scoped>
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
